import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./styles/Create.module.css";
import authStyles from "./styles/Auth.module.css";
import config from "../config";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import { BiHide, BiShow } from "react-icons/bi";

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [ready, setReady] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [hidePass, setHidePass] = useState(true);
    const [hideRePass, setHideRePass] = useState(true);
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (errorMessage !== "") {
            let to = setTimeout(() => setErrorMessage(''), 5200);
            return () => clearTimeout(to);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isLoading) {
            setLoading(false);
            axios.get(`${config.baseUrl}/api/user/reset-password/${token}`)
            .then(response => {
                let res = response.data;
                if (res.data !== null) {
                    setReady(true);
                }
                console.log(res);
            })
        }
    }, [isLoading]);

    useEffect(() => {
        if (password !== "" && rePassword !== "") {
            if (password === rePassword) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(true);
        }
    }, [password, rePassword])

    const submit = () => {
        axios.post(`${config.baseUrl}/api/user/reset-password`, {
            token, password
        })
        .then(response => {
            let res = response.data;
            if (res.status === 200) {
                setMessage(res.message);
                setTimeout(() => {
                    navigate('/login');
                }, 4550);
            } else {
                setErrorMessage(res.message);
            }
        })
    }

    if (ready) {
        return (
            <div className={authStyles.Container}>
                <div className={authStyles.Content}>
                    <div className={styles.Title} style={{color: config.primaryColor,textAlign: 'center'}}>Atur Ulang Password</div>
                    <div style={{display: 'flex',flexDirection: 'column',gap: 20,marginTop: 40}}>
                        <Input label="Buat Password Baru" value={password} onInput={e => setPassword(e.currentTarget.value)} type={hidePass ? "password" : "text"} right={
                            <div onClick={() => setHidePass(!hidePass)} style={{cursor: 'pointer'}}>
                                { hidePass ? <BiShow /> : <BiHide />}
                            </div>
                        } />
                        <Input label="Ketik Ulang Password Baru" value={rePassword} onInput={e => setRePassword(e.currentTarget.value)} type={hideRePass ? "password" : "text"} right={
                            <div onClick={() => setHideRePass(!hideRePass)} style={{cursor: 'pointer'}}>
                                { hideRePass ? <BiShow /> : <BiHide />}
                            </div>
                        } />

                        {
                            errorMessage !== "" &&
                            <div className={authStyles.errorMessage}>
                                {errorMessage}
                            </div>
                        }
                        {
                            message !== "" &&
                            <div className={authStyles.errorMessage} style={{
                                backgroundColor: `#2ecc7125`,
                                color: '#2ecc71'
                            }}>
                                {message}
                            </div>
                        }

                        <Button disabled={disabled} onClick={submit}>
                            Ubah Password
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;