import React, { useState } from "react";
import styles from "./styles/Create.module.css";
import authStyles from "./styles/Auth.module.css";
import config from "../config";
import Input from "../components/Input";
import Button from "../components/Button";
import axios from "axios";

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [done, setDone] = useState(false);

    const submit = () => {
        axios.post(`${config.baseUrl}/api/user/forget-password`, {
            email
        })
        .then(response => {
            let res = response.data;
            setDone(true);
        })
    }

    return (
        <>
            <div className={authStyles.Container}>
                <div className={authStyles.Content}>
                    <div className={styles.Title} style={{color: config.primaryColor,textAlign: 'center'}}>Lupa Password</div>
                    {
                        done ?
                        <div style={{display: 'flex',flexDirection: 'column',gap: 20,marginTop: 40}}>
                            <div style={{color: '#555',lineHeight: 2}}>Kami telah mengirim tautan untuk mengatur ulang kata sandi Anda ke email {email}. Mohon cek folder spam apabila Anda tidak dapat menemukannya</div>
                        </div>
                        :
                        <div style={{display: 'flex',flexDirection: 'column',gap: 20,marginTop: 40}}>
                            <Input label="Beri tahu alamat emailmu untuk mengatur ulang password" value={email} onInput={e => setEmail(e.currentTarget.value)} />
                            <Button onClick={submit}>Atur Ulang Password</Button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ForgetPassword;