import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import styles from "./styles/Create.module.css";
import authStyles from "./styles/Auth.module.css";
import config from "../config";
import axios from "axios";
import Input from "../components/Input";
import Separator from "../components/Separator";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { BiHide, BiShow } from "react-icons/bi";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hidePass, setHidePass] = useState(true);

    const [errorMessage, setErrorMessage] = useState('');

    const loggingIn = () => {
        axios.post(`${config.baseUrl}/api/user/login`, {
            email, password
        })
        .then(response => {
            let res = response.data;
            if (res.status === 200) {
                window.localStorage.setItem('user_data', JSON.stringify(res.user));
                navigate('/');
            } else {
                setErrorMessage(res.message);
            }
        })
        .catch(e => {
            console.error(e);
        })
    }

    useEffect(() => {
        if (errorMessage !== "") {
            let to = setTimeout(() => setErrorMessage(''), 4800);
            return () => clearTimeout(to);
        }
    }, [errorMessage]);

    // GOOGLE AUTHENTICATION START
    const authToAPI = (name, email, photo, password, expiry) => {
        axios.post(`${config.baseUrl}/api/user/login`, {
            name: name,
            email: email,
            photo: photo,
            at: password,
        }, {
            method: "POST"
        })
        .then(response => {
            let res = response.data;
            if (res.status === 200) {
                window.localStorage.setItem('gat', JSON.stringify({
                    token: password,
                    expiry,
                }))
                window.localStorage.setItem('user_data', JSON.stringify(res.user));
                navigate('/');
            }
        })
        .catch(err => {
            console.log('ERROR COK', err);
        })
    }
    const getProfile = (token, expiry) => {
        axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
                "Authorization": "Bearer " + token,
            }
        })
        .then(res => {
            res = res.data;
            authToAPI(res.name, res.email, res.picture, token, expiry);
        });
    }
    const googleLoggingIn = useGoogleLogin({
        onSuccess: response => {
            getProfile(response.access_token, response.expires_in);
        },
        flow: 'implicit'
    });
    // GOOGLE AUTHENTICATION END

    return (
        <>
            <div className={authStyles.Header}>
                <Link to={'/register'}>
                    Register
                </Link>
            </div>
            <div className={authStyles.Container}>
                <div className={authStyles.Content}>
                    <div className={styles.Title} style={{color: config.primaryColor,textAlign: 'center'}}>Pakkos</div>
                    <div style={{gap: 20,display: 'flex',flexDirection: 'column',marginTop: 60}}>
                        <Input label="Email" value={email} onInput={e => setEmail(e.currentTarget.value)} />
                        <Input label="Password" value={password} onInput={e => setPassword(e.currentTarget.value)} type={hidePass ? "password" : "text"} right={
                            <div onClick={() => setHidePass(!hidePass)} style={{cursor: 'pointer'}}>
                                { hidePass ? <BiShow /> : <BiHide />}
                            </div>
                        } />
                        {
                            errorMessage !== "" &&
                            <div className={authStyles.errorMessage}>
                                {errorMessage}
                            </div>
                        }
                        <Link to={'/forget-password'} style={{textAlign: 'right',fontSize: 12,fontWeight: 'bold',color: config.primaryColor}}>Lupa Password?</Link>
                        <Button style={{width: '100%',marginTop: 20}} onClick={loggingIn}>Login</Button>
                        <div style={{position: 'relative'}}>
                            <Separator margin="15px 0px" />
                            <div style={{position: 'absolute',top: 0,left: 0,right: 0,display: 'flex',justifyContent: 'center'}}>
                                <div style={{backgroundColor: '#fff',padding: '9px 20px',fontSize: 12,color: '#888'}}>atau</div>
                            </div>
                        </div>
                        <Button accent="secondary" style={{width: '100%'}} onClick={googleLoggingIn}>Login dengan Google</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;